import { createRouter, createWebHistory } from 'vue-router'
import LogIn from '@/components/LogIn'
import Hours from '@/components/Hours'
import About from '@/components/About'
import WorkOrders from '@/components/WorkOrders'
import WorkOrdersList from '@/components/WorkOrders/List'
import WorkOrdersView, {
  DropOffMaterials,
  PickUpMaterials,
  StartDriving,
  WorkOrdersViewOverview,
  WorkOrdersViewHours,
  WorkOrdersViewNotes,
  WorkOrdersViewHistory,
  WorkOrderReturned,
} from '@/components/WorkOrders/View'
import { useAuth } from '@eenvoud/eenvauth'
import store from './store'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    redirect: { name: 'WorkOrders' },
  },
  {
    path: '/login',
    name: 'Login',
    component: LogIn,
    meta: {
      auth: false,
    },
  },
  // Workorders
  {
    path: '/work-orders',
    name: 'WorkOrders',
    component: WorkOrders,
    meta: {
      auth: true,
    },
  },
  {
    path: '/work-orders/list/:source',
    name: 'WorkOrdersList',
    component: WorkOrdersList,
    meta: {
      auth: true,
    },
  },
  {
    path: '/work-orders/view/:id',
    name: 'WorkOrdersView',
    component: WorkOrdersView,
    redirect: { name: 'WorkOrdersViewOverview' },
    meta: {
      auth: true,
    },
    children: [
      { path: 'drop-off-materials', name: 'DropOffMaterials', component: DropOffMaterials },
      { path: 'history', name: 'WorkOrdersViewHistory', component: WorkOrdersViewHistory },
      { path: 'hours', name: 'WorkOrdersViewHours', component: WorkOrdersViewHours },
      { path: 'notes', name: 'WorkOrdersViewNotes', component: WorkOrdersViewNotes },
      { path: 'overview', name: 'WorkOrdersViewOverview', component: WorkOrdersViewOverview },
      { path: 'pick-up-materials', name: 'PickUpMaterials', component: PickUpMaterials },
      { path: 'start-driving', name: 'StartDriving', component: StartDriving },
      { path: 'returned', name: 'WorkOrderReturned', component: WorkOrderReturned },
    ],
  },
  {
    path: '/hours/:year?/:month?/:week?',
    name: 'Hours',
    component: Hours,
    meta: {
      auth: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      auth: true,
    },
  },
]

const router =  createRouter({
  history: createWebHistory(),
  routes,
})

let fetched = false

router.beforeEach(async(to) => {
  const auth = useAuth()

  if (auth.getToken() === null) {
    fetched = false
  } else if (!fetched) {
    try {
      const response = await auth.fetch()
      store.commit('app.setUser', response.data.data)
      fetched = true
    } catch (e) {
      // If the user is not logged in, it fails on auth.fetch
      return { name: 'Login' }
    }
  }

  if (auth.getToken() && to.name === 'Login') {
    return { name: 'WorkOrders' }
  } else if(auth.getToken() || to.meta.auth === false) {
    return
  } else {
    return { name: 'Login' }
  }
})

export default router
