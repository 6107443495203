<template>
  <TitleBar :workOrder="workOrder"/>
  <template v-if="workOrder.status.name === 'APPROVED'">
    Werkorder is goedgekeurd en kan niet meer worden aangepast.
  </template>
  <template v-else>
  <v-col sm="12">
    <div class="equipment">
      <h2 class="subtitle h-25px mt-0 mb-8">
        <template v-if="!workOrder.is_for_equipment_movement">
          Materiaal plaatsen op project locatie
        </template>
        <template v-else>
          <template v-if="workOrder.category === 'Materiaal brengen naar vestiging'">
            Materiaal brengen naar vestiging
          </template>
          <template v-if="workOrder.category === 'Materiaal terugbrengen naar verhuur leverancier'">
            Materiaal terugbrengen naar verhuur leverancier
          </template>
          <template v-if="workOrder.category === 'Materiaal ophalen bij vestiging'">
            Materiaal ophalen bij vestiging
          </template>
          <template v-if="workOrder.category === 'Materiaal ophalen bij verhuur leverancier'">
            Materiaal ophalen bij verhuur leverancier
          </template>
        </template>
      </h2>

      <template v-if="loading">
        Laden...
      </template>
      <template v-else>

        <template v-if="hasEquipment">
          <p>
            Heb je niet alle materialen kunnen registeren?<br>
            Neem dan contact op met de verantwoordelijke bij Van Amerongen of voeg een notitie toe aan deze werkorder.
          </p>
          <EquipmentTableDropOff
            ref="EquipmentTableDropOff"
            :addition-equipment="additionEquipment"
            :equipment="getEquipment"
            :equipmentAtLocation="placedEquipment"
            :equipmentAtVaLocation="equipmentAtVaLocation"
            :form-location-id="formLocationId"
            :move-only-rental="moveOnlyRental"
            :rental-suppliers="rentalSuppliers"
            :to-location-id="toLocationId"
            :workOrder="workOrder"
            @add-additional="addDialogVisible = true"
          />
        </template>

        <template v-else>
          <p>Er is geen voorgesteld materiaal om te plaatsen.</p>
        </template>
      </template>

    </div>

    <Dialog
      id="add-more-equipment"
      :height="'100%'"
      :maxWidth="'100%'"
      :visible="addDialogVisible"
      title="Extra materiaal toevoegen"
      @close-dialog="closeAddDialog"
    >
      <template #content>
        <v-text-field
          ref="searchField"
          v-model="search"
          clearable
          dense
          label="Zoeken"
          outlined
        ></v-text-field>
        <div class="items">
          <div v-for="item in equipmentAtVaLocationSearchResults" class="item">
            <div
              :class="{
                  'is-added': !activePlaceable(item)
                }"
              class="inner"
              @click="addAddtionalEquipment(item)"
            >
              <div v-if="item.image" class="img">
                <img :src="imageUrl(item.image, '/storage/equipment/')"/>
              </div>
              <div class="name">
                {{ item.name }}
                <strong>
                  {{ item.unique_feature ? '| ' + item.unique_feature : '' }}
                  {{item.rental_supplier_id && !moveOnlyRental ? '| ' + getRentalSupplierName(item.rental_supplier_id) : ''}}
                </strong>
              </div>
              <!-- <span class="count">Qty: {{ item.count }}</span> -->
            </div>
          </div>
          <div v-if="equipmentAtVaLocationSearchResults.length === 0" class="pl-4 pr-4">
            <p>
              <template v-if="workOrder.is_for_equipment_movement">
                <template v-if="workOrder.category === 'Materiaal ophalen bij verhuur leverancier'">
                  Geen materiaal gevonden van verhuur leverancier <strong>{{ workOrder.location.name }}</strong> op locatie <strong>{{ workOrder.location.location }}</strong>, wat aan de zoekcriteria voldoet.
                </template>
                <template v-if="workOrder.category === 'Materiaal terugbrengen naar verhuur leverancier'">
                  Geen materiaal gevonden van verhuur leverancier <strong>{{ workOrder.location.name }}</strong> op locatie <strong>{{ workOrder.location_va.location }}</strong>, wat aan de zoekcriteria voldoet.
                </template>
                <template v-if="workOrder.category === 'Materiaal ophalen bij vestiging'">
                  Geen materiaal gevonden op locatie <strong>{{ workOrder.location.location }}</strong>, wat aan de zoekcriteria voldoet.
                </template>
                <template v-if="workOrder.category === 'Materiaal brengen naar vestiging'">
                  Geen materiaal gevonden op locatie <strong>{{ workOrder.location_va.location }}</strong>, wat aan de zoekcriteria voldoet.
                </template>
              </template>
              <template v-else>
                Geen materiaal gevonden op locatie <strong>{{ workOrder.location_va.location }}</strong>, wat aan de zoekcriteria voldoet.
              </template>
            </p>
            <p>
              Klopt dit niet?<br>
              Neem dan contact op met de verantwoordelijke bij Van Amerongen of voeg een notitie toe aan deze werkorder.
            </p>
          </div>
        </div>
      </template>
    </Dialog>

  </v-col>
  </template>

</template>

<script>
import TitleBar from '@/components/WorkOrders/TitleBar'
import EquipmentTableDropOff from '@/components/UI/EquipmentTableDropOff'
import {fetchJobEquipmentLocations, getEquipmentItemsAtLocation} from '@/api/equipment'
import Dialog from '@/components/Dialog/index.vue';
import {fetchRentalSuppliers} from "@/api/rentalSuppliers";

export default {
  components: {
    Dialog,
    TitleBar,
    EquipmentTableDropOff,
  },
  props: {
    workOrder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rentalSuppliers: [],
      equipment: [],
      equipmentAtLocation: [],
      placedEquipment: {},
      // VA location is really the "From Location" this could be the location of a rental supplier
      equipmentAtVaLocation: [],
      loading: true,
      addDialogVisible: false,
      additionEquipment: [],
      search: '',
    }
  },
  computed: {
    hasEquipment() {
      // Todo
      return true
    },
    getEquipment() {
      return this.equipment
    },
    getEquipmentIds() {
      return this.equipment.map(item => item.equipment_id)
    },
    getEquipmentAtLocation() {
      return this.equipmentAtLocation
    },
    moveOnlyRental() {
      if (this.workOrder.category === 'Materiaal ophalen bij verhuur leverancier') {
        return true
      }
      if (this.workOrder.category === 'Materiaal terugbrengen naar verhuur leverancier') {
        return true
      }
      return false
    },
    formLocationId() {
      // This can be different depending on the category of the WorkOrder
      if (this.workOrder.category === 'Materiaal ophalen bij verhuur leverancier') {
        return this.workOrder.location.id
      }
      if (this.workOrder.category === 'Materiaal ophalen bij vestiging') {
        return this.workOrder.location.id
      }
      return this.workOrder.location_va.id
    },
    toLocationId() {
      // This can be different depending on the category of the WorkOrder
      if (this.workOrder.category === 'Materiaal ophalen bij verhuur leverancier') {
        return this.workOrder.location_va.id
      }
      if (this.workOrder.category === 'Materiaal ophalen bij vestiging') {
        return this.workOrder.location_va.id
      }
      return this.workOrder.location.id
    },
    equipmentAtVaLocationSearchResults() {
      const val = this.search
      let data = this.equipmentAtVaLocation;

      // If moving rental equipment, filter out all other equipment
      if(this.moveOnlyRental) {
        data = data.filter(item => item.rental_supplier_id !== null)
      }

      // If not an equipment movement WO we want to compress bulk items into 1 set
      // Hide rented equipment unless unique or composite with a unique
      if(!this.workOrder.is_for_equipment_movement) {
        data = data.filter(item => {
          if(item.rental_supplier_id) {
            return !!(item.unique_feature || item.hasUnique);
          }
          return true;
        })
      }

      if(!val) {
        return data
      }

      return data.filter((item) => {
        return item.name.toLowerCase().includes(val.toLowerCase()) ||(item.unique_feature && item.unique_feature.toLowerCase().includes(val.toLowerCase())) || (item.rental_supplier_id && this.getRentalSupplierName(item.rental_supplier_id).toLowerCase().includes(val.toLowerCase()))
      })
    }
  },
  methods: {
    closeAddDialog() {
      this.addDialogVisible = false
      setTimeout(() => {
        this.search = ''
      }, 500)
    },
    imageUrl(url, path = '') {
      if (!url) {
        return ''
      }
      return `${import.meta.env.VITE_APP_BASE_API_URL}${path}${url}`
    },
    getAmountAlreadyPlaced(equipmentItem, render=false) {
      let placed = 0
      if(equipmentItem.is_composite || equipmentItem.composite_id) {
        placed = this.placedEquipment.composite[equipmentItem.composite_id ?? equipmentItem.equipment_id] ?? 0;
      } else if (equipmentItem.is_unique || equipmentItem.unique_feature) {
        placed = this.placedEquipment.unique[equipmentItem.equipment_id]?.length ?? 0
      } else {
        placed = this.placedEquipment.bulk[equipmentItem.equipment_id] ?? 0;
      }
      if(!render) return placed

      const quantity = this.getAmountSuggested(equipmentItem)

      return `${quantity} (${placed} geplaatst)`
    },
    activePlaceable(equipmentItem) {
      if(equipmentItem.unique_feature) {
        if(this.$refs.EquipmentTableDropOff.uniqueItemsUsed.includes(equipmentItem.id)) {
          return false
        }
        return true
      }
      if(equipmentItem.composite_id && equipmentItem.hasUnique) {
        return true
      }

      let toPlace = 0
      // If it is in the already placed additional equipment
      if (this.additionEquipment.filter((item) => {
        if(item.unique_feature) {
          return item.equipment_id == equipmentItem.equipment_id && item.id == equipmentItem.id
        }
        return item.equipment_id == equipmentItem.equipment_id
      }).length) {
        return false
      }
      const equipment = this.equipment.filter(item => item.equipment_id == equipmentItem.equipment_id)
      for (const i in equipment) {
        toPlace += equipment[i].quantity ?? 0
      }

      return !toPlace || this.getAmountAlreadyPlaced(equipmentItem) >= toPlace
    },
    async fetchEquipmentAtLocation() {
      // For this we are only  interested in items being placed with the workOrder.
      const placedByWorkOrderId = this.workOrder.id

      const equipmentAtLocation = await fetchJobEquipmentLocations(
        this.workOrder.job_id,
        1,
        placedByWorkOrderId,
        null,
        placedByWorkOrderId
      )

      let data = {
        composite: {},
        bulk: {},
        unique: [],
      }

      for (const i in equipmentAtLocation.data.data) {
        const item = equipmentAtLocation.data.data[i]
        if(item.collected_at) continue
          if (item.composite_equipment_id) {
            if (!data.composite[item.composite_equipment_id]) {
              data.composite[item.composite_equipment_id] = 0
            }
            data.composite[item.composite_equipment_id] += parseInt(item.count)
          }  else if(item.unique_feature) {
            if (!data.unique[item.equipment_id]) {
              data.unique[item.equipment_id] = []
            }
            data.unique[item.equipment_id].push(item.HASH)
          } else {
            if (!data.bulk[item.equipment_id]) {
              data.bulk[item.equipment_id] = 0
            }
            data.bulk[item.equipment_id] +=  parseInt(item.total_items)
          }
      }
      this.placedEquipment = data
    },
    async fetchEquipment() {
      if (!this.workOrder || !this.workOrder.suggestedEquipment) {
        return {};
      }
      for (let i = 0; i < this.workOrder.suggestedEquipment.length; i++) {
        let noLocation = true
        let items = []
        // check for unique items
        let isUnique = false
        const equipmentAtVaLocation = this.equipmentAtVaLocation.filter(item => item.equipment_id === this.workOrder.suggestedEquipment[i].equipment_id)
        let equipmentAtVaLocationItem = equipmentAtVaLocation[0] ?? null;
        if (equipmentAtVaLocationItem?.items) {
          equipmentAtVaLocationItem.items = null;
        }
        if (
          !this.workOrder.suggestedEquipment[i].equipment.is_bulk ||
          equipmentAtVaLocationItem?.hasUnique ||
          this.workOrder.suggestedEquipment[i].equipment?.combinedEquipment.filter(item => item.is_bulk === 0).length
        ) {
          isUnique = true
          if (!equipmentAtVaLocationItem) {
            equipmentAtVaLocationItem = { hasUnique: {} }
            items = false
          }
        }

        const placedEquipment = 0

        let addingRounds = 1
        let amount = this.workOrder.suggestedEquipment[i].quantity
        let quantity = this.workOrder.suggestedEquipment[i].quantity
        // unique items need one row for each item
        if (isUnique) {
          addingRounds = this.workOrder.suggestedEquipment[i].quantity - placedEquipment
          amount = 0
          quantity = 1
        }
        if (addingRounds < 1) {
          continue
        }
        for (let j = 0; j < addingRounds; j++) {
          const equipmentItem = {
            no_loc: noLocation,
            equipment_id: this.workOrder.suggestedEquipment[i].equipment_id,
            equipment: equipmentAtVaLocationItem,
            equipment_item_id: null,
            amount: amount,
            image_url: this.imageUrl(this.workOrder.suggestedEquipment[i].equipment.image_href),
            is_composite: this.workOrder.suggestedEquipment[i].equipment.is_combi,
            job_id: this.workOrder.job_id,
            work_order_id: this.workOrder.id,
            from_location_id: this.formLocationId,
            location_id: this.workOrder.location.id,
            supplier_id: this.workOrder.suggestedEquipment[i].rental_supplier_id,
            note: this.workOrder.suggestedEquipment[i].note,
            location_change_type: "place",
            // extra
            quantity: quantity,
            name: this.workOrder.suggestedEquipment[i].equipment.name,
            is_unique: isUnique,
            items: items,
            identifier: `suggested-${i}-${j}`
          }
          if (!isUnique) {
            equipmentItem.amount = Math.max(equipmentItem.quantity - placedEquipment, 0)
          }
          this.equipment.push(equipmentItem)
        }
      }
      // Combine already placed lines for unique equipment

      let filterItems = {}

      for (const suggestedItem of this.equipment) {
        if(!filterItems[suggestedItem.equipment_id]) {
          filterItems[suggestedItem.equipment_id] = {
            equipment: {
              equipment_id: suggestedItem.equipment_id,
              is_unique: suggestedItem.is_unique,
              is_composite: suggestedItem.is_composite,
              name: suggestedItem.name,
            },
            items: []
          };
        }
        filterItems[suggestedItem.equipment_id].items.push(suggestedItem)
      }

      this.equipment = []

      for(const suggestedEquipmentId in filterItems) {
        const equipmentItem = filterItems[suggestedEquipmentId].equipment
        const countPlaced = this.getAmountAlreadyPlaced(equipmentItem)

        let totalSuggested = 0;
        for(const i in filterItems[suggestedEquipmentId].items) {
          totalSuggested = totalSuggested + filterItems[suggestedEquipmentId].items[i].quantity
        }
        // let's set the already Placed Status
        for(const i in filterItems[suggestedEquipmentId].items) {
          const index = parseInt(i) + 1

          let singleItem = filterItems[suggestedEquipmentId].items[i]
          if(equipmentItem.is_composite && equipmentItem.is_unique) {
            if(index <= countPlaced) {
              singleItem.isPlaced = true
              singleItem.isPlacedCount = 1
            } else {
              singleItem.isPlaced = false
              singleItem.isPlacedCount = 0
            }
          } else if(equipmentItem.is_unique) {
            if(countPlaced >= index) {
              singleItem.isPlaced = true
              singleItem.isPlacedCount = 1
            } else {
              singleItem.isPlaced = false
              singleItem.isPlacedCount = 0
            }
          } else {
            if(countPlaced >= totalSuggested) {
              singleItem.isPlaced = true
              singleItem.isPlacedCount = countPlaced
            } else {
              singleItem.isPlaced = false
              singleItem.isPlacedCount = countPlaced
            }
          }
          singleItem.totalSuggested = totalSuggested
          this.equipment.push(singleItem)
        }
      }
      this.loading = false
    },
    async fetchRentalSuppliers() {
      const rentalSuppliers = await fetchRentalSuppliers()
      this.rentalSuppliers = rentalSuppliers.data?.data ?? []
    },
    async fetchEquipmentAtVaLocation() {
      try {
        const equipmentItemsAtLocation = await getEquipmentItemsAtLocation(
          this.workOrder.job_id,
          this.formLocationId,
          !this.moveOnlyRental ? 'all' : this.workOrder.location.rental_supplier_id
        )
        this.equipmentAtVaLocation = equipmentItemsAtLocation.data
      } catch (error) {
        alert('Failed to fetch equipment list: ', error)
      } finally {
        // this.loading = false
      }
    },
    addAddtionalEquipment(item) {
      if (!this.activePlaceable(item)) {
        return false
      }
      item.supplier_id =  item.rental_supplier_id

      this.additionEquipment.push(item)
      this.addDialogVisible = false
      setTimeout(() => {
        this.search = ''
      }, 500)
    },
    getRentalSupplierName(id) {
      const supplier = this.rentalSuppliers.filter(item => item.id === id)
      return supplier.length > 0 ? supplier[0].name : ''
    },
  },
  watch: {
    addDialogVisible(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.searchField.focus();
        });
      }
    },
  },
  async created() {
    await this.fetchRentalSuppliers()
    await this.fetchEquipmentAtVaLocation()
    await this.fetchEquipmentAtLocation()
    await this.fetchEquipment()
  },
}
</script>
<style lang="scss" scoped>
p {
  padding: 5px 0;
}

#add-more-equipment {
  .items {
    margin: -5px -20px 0 -10px;

    .item {
      width: 33%;
      float: left;
      padding: 5px;
      font-size: 14px;

      .inner {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 80px;
        border: 1px solid #ccc;
        padding: 15px;

        .img {
          float: left;
          width: 60px;
          margin-right: 10px;

          img {
            display: block;
            max-width: 50px;
            max-height: 60px;
          }
        }

        &.is-added {
          cursor: default;
          opacity: 0.25;
        }

        position: relative;

        .count {
          position: absolute;
          right: 0;
          top: 0;
          padding: 2px 5px;
          background-color: #ccc;
        }
      }
    }
  }
}

// on screen size smaller than 768px
@media (max-width: 768px) {
  #add-more-equipment {
    .items {
      margin: -5px -10px 0 -10px;

      .item {
        width: 50%;
      }
    }
  }
}

// on screen size smaller than 600px
@media (max-width: 600px) {
  #add-more-equipment {
    .items {
      margin: -5px -10px 0 -10px;

      .item {
        width: 100%;
      }
    }
  }
}

// on screen size bigger than 1200px
@media (min-width: 1200px) {
  #add-more-equipment {
    .items {
      margin: -5px -10px 0 -10px;

      .item {
        width: 25%;
      }
    }
  }
}
</style>
